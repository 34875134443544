import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="counter"
export default class extends Controller {
  static targets = ["output"]
  static values=  {
    currentCount: { type: Number, default: 0 },
  }
  
  connect() {
    if (this.hasOutputTarget) {
      this.outputTarget.value = this.currentCountValue;
    }
  }
  
  increase() {
    if (this.currentCountValue >= this.maxElementsValue) return;
    
    this.currentCountValue++;
    this.outputTarget.value = this.currentCountValue;
  }

  decrease() {
    if (this.currentCountValue <= 0) return;
    
    this.currentCountValue--;
    this.outputTarget.value = this.currentCountValue;
  }
}
