  import { Controller } from "@hotwired/stimulus"

  // Connects to data-controller="bild"
  export default class extends Controller {
    static targets = ["bildSelect", "bildPreview", "bildHidden", "loeschenButton", "ersetzenButton"]
    
    connect() {
      if (this.hasLoeschenButtonTarget) {
        if (!this.bildPreviewTarget.src.includes("s3")) {
          this.loeschenButtonTarget.classList.add("hidden");
          this.ersetzenButtonTarget.classList.add("hidden");
        }
      } 
    }

    setBild() {
      this.bildSelectTarget.click();
      if (this.hasLoeschenButtonTarget && this.hasErsetzenButtonTarget) {
        this.loeschenButtonTarget.classList.add("hidden");
        this.ersetzenButtonTarget.classList.add("hidden");
      }
    }

    setBildPreview() {
      let bild = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.bildHiddenTarget.value = e.target.result;
        this.bildPreviewTarget.src = e.target.result;
      };
      reader.readAsDataURL(bild);
      if (this.hasLoeschenButtonTarget) {
        this.loeschenButtonTarget.classList.remove("hidden");
        this.ersetzenButtonTarget.classList.remove("hidden");
        this.bildSelectTarget.classList.add("hidden");
      }
    }
    
    entferneBild() {
      this.loeschenButtonTarget.classList.add("hidden");
      this.ersetzenButtonTarget.classList.add("hidden");
      this.bildSelectTarget.classList.remove("hidden");
      this.resetTargets();
    }
    
    ersetzeBild() {
      this.resetTargets();
      this.setBild();
    }
    
    resetTargets() {
      this.bildHiddenTarget.value = "";
      this.bildPreviewTarget.src = "";
      this.bildSelectTarget.value = ""
      this.bildSelectTarget.value = "";
    }
  }
