import { BridgeComponent } from "@hotwired/hotwire-native-bridge"
import { post } from "@rails/request.js";
import { mobileAppPlatform, mobileAppVersion } from "../../packs/global/utils";

// Connects to data-controller="native--notification"
export default class extends BridgeComponent {
  static component = "notification"

  connect() {
    super.connect()
    this.getCurrentFCMToken()
  }

  getCurrentFCMToken() {
    this.send("getCurrentFCMToken", {}, message =>  {
      const token = message.data.token
      const uniqueDeviceId = message.data.uniqueDeviceId
      this.forwardTokenToServer(token, uniqueDeviceId)
    })
  }

  async forwardTokenToServer(token, uniqueDeviceId) {
    // Nur weitermachen, wenn wir den FCM Token noch nicht kennen
    if (this.previousFCMToken === token) return

    const body = {
      token: token,
      platform: mobileAppPlatform,
      unique_device_id: uniqueDeviceId,
      app_version: mobileAppVersion,
    };
    const response = await post("/push_notification_tokens", { body: body });

    if (response.ok) {
      localStorage.setItem("currentFCMToken", token);
      console.log("FCM Token wurde an den Server gesendet und gespeichert");
    } else {
      console.log("FCM Token wurde an den Server gesendet aber Server sagt Nein");
    }
  }

  get previousFCMToken() {
    return localStorage.getItem("currentFCMToken");
  }
}
