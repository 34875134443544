import swal from "sweetalert";
import { isMobileApp } from "../global/utils"

if (!isMobileApp) {
  Turbo.config.forms.confirm = (message, element) => {
    const title = element.dataset.turboConfirmTitle || I18n.confirm;
    return new Promise((resolve, reject) => {
      swal({
        title: title,
        text: message,
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        resolve(willDelete);
      });
    });
  };
}
