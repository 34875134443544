import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select"
export default class extends Controller {
  static values=  {
    condition: { type: String },
    selector: { type: String },
  };

  // Inputs werden verteckt und disabled, so das sie in Form nicht Submitted werden
  showOrHide({ target }) {
    let selectedElements = document.querySelectorAll(this.selectorValue);
    if (selectedElements) {
      selectedElements.forEach(element => {
        element.style.display = (target.value !== this.conditionValue) ? "none" : "";
        element.disabled = (target.value !== this.conditionValue);
      });
    }
  };
}
