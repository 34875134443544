import { BridgeComponent } from "@hotwired/hotwire-native-bridge"
import { post } from '@rails/request.js'
import { debounce, mobileAppUniqueDeviceId } from "../../packs/global/utils";

// Dies ist unser Haupt Bridge Controller, mit dem wir Nachrichten an die Hotwire Native App senden können.
// Damit wir nicht für kleinere Dinge, eine eigenständige component machen müssen

// Connects to data-controller="native--bridge"
export default class extends BridgeComponent {
  static component = "bridge"

  connect() {
    super.connect()

    // Wir senden ein "connect" runter, damit die Mobile App jederzeit auf dieses Event antworten kann
    // (damit die Mobile App etwas hochsenden kann, muss zuerst etwas runtergesendet werden)
    this.send("connect", {}, (message) => {
      const callbackReason = message.data.callbackReason
      switch (callbackReason) {
        case "didPressMenuButton":
          this.dispatchEvent("mobileapp:toggle-sidedrawer")
          break
        default:
          console.log("#connect unknown callbackReason " + callbackReason)
      }
    })
  }

  // Wird debounce das CustomEvent erstellen, falls aus irgendeinem Grund die App kurz nacheinander eine Nachricht sendet
  dispatchEvent = debounce((eventName) => {
    const event = new CustomEvent(eventName);
    window.dispatchEvent(event);
  }, 10)

  // Wenn eine Turbo Native App abgemeldet werde soll, machen wir manuell einen request auf /mobile_app_abmelden um alle MobileAppBezogene Daten zu löschen
  // anschliessend informieren wir die App per JS Bridge um der App die Möglichkeit zu geben, gespeicherte Daten zu löschen.
  // Zu guter letzt leiten wir den Benutzer auf die Login Seite weiter.
  async signOut({ params: { mitRedirect } }) {
    const response = await post('/mobile_app_abmelden', { body: { unique_device_id: mobileAppUniqueDeviceId } })
    if (response.ok) {
      localStorage.removeItem("currentFCMToken");
      if (mitRedirect === true) {
        Turbo.visit("/benutzer/sign_in");
      }
    }
  }
}
