export const de = {
  ja: 'Ja',
  nein: 'Nein',
  new: 'Erstellen',
  speichern: 'Speichern',
  vorschau: 'Vorschau',
  allgemein: 'Allgemein',
  disziplin: 'Disziplin',
  disziplinen: 'Disziplinen',
  verantwortlich: 'Verantwortlich',
  status: 'Status',
  schliessen: 'schliessen',
  unbekannte_frage: 'Unbekannte Frage:',

  veranstaltungen: 'Veranstaltungen',
  veranstaltungen_nicht_ohne_rechte: 'Um eine Veranstaltung zu erfassen, müssen Sie über die entsprechenden Benutzerrechte verfügen.',
  veranstaltungen_rechte_liste: 'Sie haben bereits folgende Rechte beantragt:',
  veranstaltungen_rechte_mehr_beantragen: 'Bitte beantragen Sie diese mittels untenstehendem Formular:',
  veranstaltungen_rechte_beantragen: 'Bitte beantragen Sie diese mittels untenstehendem Formular:',
  veranstaltungen_rechte_warten: 'Bitte warten Sie auf Freischaltung Ihrer Rechte für Veranstalter. Danach können Sie hier Veranstaltungen kopieren und neue Veranstaltungen erfassen.',
  akzeptiert: 'Akzeptiert',
  kommentar: 'Kommentar',
  rechte_anfordern: 'Absenden',

  datum: 'Datum',
  ort: 'Ort',
  typ: 'Typ',
  modus: 'Modus',
  vorgesehene_pruefungen: 'Vorgesehene Prüfungen',
  regionalverband: 'Regionalverband',
  regionalverband_gekuerzt: 'RV',
  sektion: 'Sektion',
  tag: 'Tag',
  anlass: 'Anlass',
  anlaesse: 'Anlässe',
  keine_anlaesse: 'Wir werden keine Regionalverband-Anlässe durchführen',
  hat_stilpruefungen: 'Werden Stilprüfungen zum Erwerb der Lizenz R durchgeführt?',
  stilpruefungen: 'Stilprüfungen zum Erwerb der Lizenz R',
  anzahl_b100_stilpruefungen: 'Anzahl B100 Stilprüfungen',
  anzahl_p95_stilpruefungen: 'Anzahl P95 Stilprüfungen',
  zusatzinfos_anlaesse: 'Zusatzinfos zu Anlässe',
  durchfuehrungstage: 'Durchführungstage',
  veranstalter_ist_verbandsmitglied: 'Swiss Equestrian-Mitglied',
  mitglied: 'Mitglied',
  nichtmitglied: 'Nicht-Mitglied',
  aktiv: 'Aktiv',
  inaktiv: 'Inaktiv',
  sektion_rayon: 'Sektion / Rayon',
  partner_id: 'Partner ID',
  alle: 'Alle',
  filter: 'Filter',
  veranstaltung_anmelden: 'Neue Veranstaltung',
  nennschluss: 'Nennschluss',
  nennphase_start: 'Nennphase ab',
  nachnennphase_start: 'Start der Nachnennphase',
  nennphase_ab_21_uhr: '21:00',
  nennvariante_nennschluss: 'Nennschluss',
  nennvariante_nennphase: 'Nennphase',
  id: 'ID',
  vorname_name: 'Vorname Name',
  rolle: 'Rolle',
  benutzer: 'Benutzer',
  aktuar: 'Aktuar',

  anmeldung_neue_veranstaltung: 'Anmeldung neue Veranstaltung',
  veranstalter: 'Veranstalter',
  sprache: 'Sprache',
  rv_anlaesse: 'RV Anlässe',
  plz: 'PLZ',
  kanton: 'Kanton',
  plz_ort_kanton: 'PLZ - Ort - Kanton',
  website: 'Website',
  von: 'von',
  bis: 'bis',
  ok_praesident_in: 'OK Präsident/in',
  oas_verantwortliche_r: 'OAS Verantwortliche/r',
  ausschreibungen_kontaktdaten: 'Ausschreibung Telefon / Fax',
  nennschluss_definitiv: 'Nennschluss',
  ausschreibungs_ergaenzungen: 'Bemerkungen und Ergänzungen zu den Prüfungen',

  access_key: 'Access-Key',
  im_nennsystem_aktiviert: 'Im Nennsystem aktiviert?',
  bietet_stallungen_an: 'Bietet Stallungen an',
  ok_praesident: 'OK Präsident/in',
  ok_sekretaer: 'OK Sekretär/in',
  oas_verantwortlicher: 'OAS Verantwortliche/r',

  veranstaltungsdaten: 'Veranstaltungsdaten',
  ausschreibung: 'Online-Ausschreibung',
  ausschreibung_starten: 'Ausschreibung starten',
  weitere_informationen: 'Weitere Informationen',
  konto_infos: 'Konto / Infos',
  nennungen: 'Nennungen',
  dokumente: 'Dokumente',

  stallungen: 'Stallungen',
  kopfdaten: 'Kopfdaten',
  beteiligungen: 'Personen',
  pruefungen: 'Prüfungen',
  fussdaten: 'Fussdaten',

  berechnungsschema: 'Berechnungsschema',
  betrag: 'Betrag',
  total_betrag: 'Total Betrag',
  stallungsangebot_aktiv: 'Stallungen aktiv',
  erste_titelzeile: 'Titelzeile 1',
  zweite_titelzeile: 'Titelzeile 2',
  dritte_titelzeile: 'Titelzeile 3',

  person: 'Person',
  funktion: 'Funktion',
  telefonnummer: 'Telefonnummer',
  nummer: 'Nummer',
  nummer_nr: 'Nr.',
  kategorie: 'Kategorie',
  wertung: 'Wertung',
  wertung_text: 'Wertung',
  springen_anzahl_stufen: 'Anzahl Stufen',
  equipe: 'Equipe',
  equipe_anzahl_nennungen: 'Equipe',
  lizenzen: 'Lizenzen',
  nenngeld: 'Nenngeld',
  nenngeld_ng: 'NG',
  nenngeld2: 'Nenngeld Nachnennphase',
  nachnennphase_nenngeld_erhoehung: 'Nenngeld erhöhung Nachnennphase',
  preise: 'Preise',
  bemerkungen: 'Bemerkungen',
  bemerkung_rayon: 'Bemerkung / Rayon',
  email: 'E-Mail',
  telefon_m: 'Telefon M',
  telefon_p: 'Telefon P',
  telefon_g: 'Telefon G',
  n_reiter_nur_4_und_5_jaehrige_pferde: 'N-Reiter nur 4- und 5-jährige Pferde',
  r_reiter_nur_4_und_5_jaehrige_pferde: 'R-Reiter nur 4- und 5-jährige Pferde',

  brevet: 'Brevet',
  regional: 'Regional',
  national: 'National',
  paar: 'Paar',
  pruefung_hat_gwp_beschraenkungen: 'Prüfung mit GWP Beschränkungen',
  gewinnpunkte_pferd_brevet_minimal: "Pferd Brevet min.",
  gewinnpunkte_pferd_brevet_maximal: "Pferd Brevet max.",
  gewinnpunkte_reiter_brevet_minimal: "Reiter Brevet min.",
  gewinnpunkte_reiter_brevet_maximal: "Reiter Brevet max.",
  gewinnpunkte_paar_brevet_minimal: "Paar Brevet min.",
  gewinnpunkte_paar_brevet_maximal: "Paar Brevet max.",
  gewinnpunkte_pferd_regional_minimal: "Pferd regional min.",
  gewinnpunkte_pferd_regional_maximal: "Pferd regional max.",
  gewinnpunkte_reiter_regional_minimal: "Reiter regional min.",
  gewinnpunkte_reiter_regional_maximal: "Reiter regional max.",
  gewinnpunkte_paar_regional_minimal: "Paar regional min.",
  gewinnpunkte_paar_regional_maximal: "Paar regional max.",
  gewinnpunkte_pferd_national_minimal: "Pferd national min.",
  gewinnpunkte_pferd_national_maximal: "Pferd national max.",
  gewinnpunkte_reiter_national_minimal: "Reiter national min.",
  gewinnpunkte_reiter_national_maximal: "Reiter national max.",
  gewinnpunkte_paar_national_minimal: "Paar national min.",
  gewinnpunkte_paar_national_maximal: "Paar national max.",

  neue_titelzeile: 'Neue Titelzeile',
  neue_pruefung: 'Neue Prüfung',
  titelzeile_erstellen: 'Titelzeile erstellen',
  pruefung_erstellen: 'Prüfung erstellen',
  titelzeile_bearbeiten: 'Titelzeile bearbeiten',
  pruefung_bearbeiten: 'Prüfung bearbeiten',

  cancel: 'Abbrechen',
  save: 'Speichern',
  create: 'Erstellen',
  add: 'Hinzufügen',
  edit: 'Bearbeiten',
  delete: 'Löschen',
  reset: 'Zurücksetzen',
  copy: 'Kopieren',
  bestaetigen: 'Bestätigen',
  show: 'Ansehen',
  accept: 'Akzeptieren',

  veranstaltung_kopieren_titel: 'Veranstaltung kopieren',
  veranstaltung_kopieren_beschreibung: "Wenn bereits Veranstaltungen vorhanden sind, können Sie die Daten einer bestehenden Veranstaltung übernehmen. Wählen Sie einfach die entsprechende Veranstaltung aus der Liste und starten Sie den Vorgang mit 'Kopieren'",
  veranstaltung_kopieren_button: 'Kopieren',
  veranstaltung_nicht_kopieren_titel: 'Neue Veranstaltung',
  veranstaltung_nicht_kopieren_beschreibung: "Sie können eine neue Veranstaltung erstellen. Weiter geht es mit einem Klick auf 'Erfassung starten'",
  veranstaltung_nicht_kopieren_button: 'Erfassung starten',

  ausschreibung_kopieren_titel: 'Ausschreibung kopieren',
  ausschreibung_kopieren_beschreibung: "Wenn Sie bereits Ausschreibungen gemacht haben, können Sie sämtliche Daten von einer vergangenen Ausschreibung übernehmen. Wählen Sie einfach die entsprechende Veranstaltung aus der Liste und starten Sie den Vorgang mit 'Kopieren'",
  ausschreibung_kopieren_button: 'Kopieren',
  ausschreibung_nicht_kopieren_titel: 'Ausschreibung erfassen',
  ausschreibung_nicht_kopieren_beschreibung: "Ist dies Ihre erste Ausschreibung? Haben sie keine Ausschreibung mit passenden Daten für ihre akuelle Veranstaltung. Dann erfassen sie die nötigen Daten mit dem Online-Ausschreibungssystem. Weiter geht es mit einem Klick auf 'Ausschreiben'",
  ausschreibung_nicht_kopieren_button: 'Ausschreiben',

  titel: 'Titel',
  index: 'Index',
  inhalt: 'Inhalt',
  neuer_fusstext: 'Neuer Fusstext',
  neue_beteiligung: 'Neue Beteiligung',
  sichtbar: 'sichtbar',
  name: 'Name',
  nr: 'Nr',
  details: 'Details',
  nennvariante: 'Nennvariante',
  ng: 'NG',
  software: 'Software',
  keine: 'Keine',
  accept_sw_terms: 'Vereinbarung zwischen SVPS und Veranstalter gelesen und akzeptiert',
  please_accept_sw_terms: 'Bitte lesen und akzeptieren Sie die Vereinbarung zwischen SVPS und Veranstalter',
  startkartenbestellung: 'Startkartenbestellung',
  anzahl_bestellte_startkarten: 'Anzahl bestellte Nennstartkarten',
  startkarten_versandadresse: 'Startkarten Versandadresse',
  kontodaten: 'Kontodaten',
  zahlungsinformationen: 'Zahlungsinformationen',
  kontoart: 'Kontoart',
  name_bank: 'Name der Bank',
  iban: 'IBAN',
  kontoinhaber: 'Kontoinhaber',
  name_firma: 'Name / Firma',
  strasse: 'Strasse',
  zusatz: 'Zusatz',
  land: 'Land',
  post: 'Post',
  bank: 'Bank',
  liechtenstein: 'LIE - Liechtenstein',
  schweiz: 'SUI - Schweiz',

  stallung_pauschal: 'Pauschal',
  stallung_pauschal_pro_tag: 'Pauschal pro Tag',
  stallung_variabel: 'Variabel',
  stallung_variabel_pro_tag: 'Variabel pro Tag',

  stallungsangebot_erfassen: 'Stallungsangebot erfassen',
  stallungsangebot_zurueckziehen: 'Stallungsangebot zurückziehen',

  total_nenngeld: 'NG total',
  total_nennungen: 'Total Nennungen',
  zaehlbare_nennungen: 'Zählbare Nennungen',
  hc_abgem_falschn_zurueckgew: 'hc / abgem. / falschn. / zurückgew.',
  max_nennungen: 'Max. Nennungen',
  ons_status: 'ONS Status',
  erstellt_modifiziert: 'Erstellt / Modifiziert',
  gruppenname: 'Name',
  longenfuehrer: 'Longenführer',
  telefon: 'Telefon',
  pferd_e: 'Pferd(e)',
  lizenz_nr: 'Lizenz Nr.',
  reiter_fahrer: 'Reiter / Fahrer',
  nennungen_bis_nennschluss: 'Nennungen bis Nennschluss',
  total_bis_nennschluss: 'Total bis Nennschluss',
  nennungen_nachnennphase: 'Nennungen Nachnennphase',
  total_nachnennphase: 'Total Nachnennphase',
  pruefung_nr: 'Prüfung Nr',
  veranstaltung: 'Veranstaltung',
  nennungen_per: 'Nennungen per',
  anzahl_hc: 'Anzahl HC',
  abmeldungen: 'Abmeldungen',
  falschnennungen: 'Falschnennungen',
  zurueckgewiesene: 'Zurückgewiesene',
  total_zaehlbare_nennungen: 'Total zählbare Nennungen',
  anzahl_startplaetze_max: 'Anzahl Startplätze max',
  nachnennphase: 'Nachnennphase',
  anzahl_veranstalter_startplaetze: 'Anzahl Veranstalter-Startplätze',
  nennungen_auf_pruefungen: 'Nennungen auf Prüfungen',
  anzahl_nennungen: 'Anz. Nennungen',
  status_veranstaltung: 'Status Veranstaltung',
  status_nennung: 'Status Nennung',
  fei_event_type_code: 'Event',
  fei_competition_code: 'Competition',
  fei_event_type_level: 'Level',
  bedding: 'Bedding',
  bemerkungen_pferd: 'Bemerkungen Pferd (geht direkt an den Veranstalter)',
  bemerkungen_reiter: 'Bemerkungen Reiter',
  bemerkungen_athlet: 'Bemerkung Athlet (geht direkt an den Veranstalter)',
  geht_direkt_an_veranstalter_info: 'Diese Bemerkungen gehen direkt an den Veranstalter',
  slot: 'Slot',
  lizenz_brev_nr: 'Lizenz / Brevet Nr.',
  fei_lizenz: 'FEI-Lizenz',
  verein: 'Verein',
  pass_nr: 'Pass-Nr.',
  fei_pass_nr: 'FEI Pass-Nr.',
  pferde_name: 'Pferde Name',
  signalement: 'Signalement',
  besitzer: 'Besitzer',
  pferd_hinzufuegen: 'Pferd hinzufügen',
  person_hinzufuegen: 'Person hinzufügen',
  wirklich_loeschen: 'Wollen Sie wirklich löschen?',
  veranstaltungsort: 'Veranstaltungsort',
  event_datum: 'Event Datum',
  athletes: 'Athletes',
  reiter: 'Reiter',

  nachnennphase_starten_pruefung_text: 'Wollen Sie die Nachnennphase für Prüfung %{nummer} vom %{datum} - %{kategorie} starten?',
  nachnennphase_stoppen_pruefung_text: 'Wollen Sie die Nachnennphase für Prüfung %{nummer} vom %{datum} - %{kategorie} stoppen?',

  geplante_teilpruefungen: 'Geplante Teilprüfungen',
  geplante_teilpruefungen_warnung: 'Nur Teilprüfungen auswählen, welche wirklich durchgeführt werden!',

  gespann: 'Gespann',
  gespanne_fuer_fahren: 'Gespanne',

  lizenzierung_gruppe: 'Lizenzen',
  lizenzierung_gruppe_text: 'Lizenzen',
  dressur_teilnehmer: 'Teilnehmer',
  dressur_rechteck: 'Rechteck',
  fahren_nur_pony: 'Nur Ponys',
  fahren_nur_pferde: 'Nur Pferde',
  pony: 'Pony',
  pferd: 'Pferd',
  fuer_pferde: 'Für Pferde',
  fuer_ponies: 'Für Pony',

  concours_complet_dressur: 'Dressur',
  concours_complet_springen: 'Springen',
  concours_complet_cross: 'Cross',

  fahren_dressur: 'Dressur',
  fahren_gelaende: 'Gelände',
  fahren_hindernis: 'Kegelfahren',
  fahren_derby: 'Derby',
  fahren_multitest: 'Multitest',
  fahren_freier_text: 'Offen',

  concours_complet_dressur_aktiv: 'Dressur',
  concours_complet_springen_aktiv: 'Springen',
  concours_complet_cross_aktiv: 'Cross',

  fahren_dressur_aktiv: 'Dressur',
  fahren_gelaende_aktiv: 'Gelände',
  fahren_hindernis_aktiv: 'Hindernis',
  fahren_derby_aktiv: 'Derby',
  fahren_multitest_aktiv: 'Multitest',
  fahren_freier_text_aktiv: 'Offen',

  fahren_gespanne_1_aktiv: '1-Sp',
  fahren_gespanne_2_aktiv: '2-Sp',
  fahren_gespanne_4_aktiv: '4-Sp',
  fahren_gespanne_tandem_aktiv: 'Tandem',

  fahren_gespanne_1_label: '1',
  fahren_gespanne_2_label: '2',
  fahren_gespanne_4_label: '4',
  fahren_gespanne_tandem_label: 'tandem',

  endurance_distanz: 'Distanz',
  endurance_teilnehmer: 'Teilnehmer',
  endurance_schlaufen: 'Schlaufen/km',

  ons_status_keinen: 'Keinen',
  ons_status_nennphase: 'Nennphase',
  ons_status_nennschluss: 'Nennschluss',
  ons_status_nachnennphase: 'Nachnennphase',
  ons_status_abgeschlossen: 'Abgeschlossen',
  ons_status_nachnennphase_geplant: 'Nachnennphase geplant',

  veranstalter_startplaetze: 'Veranstalter-Startplätze',
  nachnennphase_starten: 'Nachnennphase starten',
  nachnennphase_stoppen: 'Nachnennphase stoppen',
  anzahl_nennungen_nachnennphase: 'Max. Anzahl Nennungen',
  nenngeld_nachnennphase: 'Nenngeld Nachnennphase',
  kommentar_nachnennphase: 'Hinweis auf Ausschreibung (optional)',

  pruefung_warnung_dressur_1: 'Wir möchten Sie darauf hinweisen folgende Punkte zu beachten und gründlich zu prüfen:',
  pruefung_warnung_dressur_2: 'Es ist nicht gestattet gemischte Prüfungen für B, R und N (Dressur) Reiter auszuschreiben. Gemischte Prüfungen für Inhaber des Brevets und R-Lizenzierte, sowie für R- und N-Lizenzierte sind gestattet, mit allfälligen Beschränkungen für die Lizenzierten (z.B. Gewinnpunkte, Alter des Pferdes, Klassierungen usw.).',
  pruefung_warnung_dressur_3: 'Die Grösse des Rechtecks muss nur in der Ausschreibung angegeben werden, wenn dies nicht aus dem Dressurprogramm ersichtlich ist.',
  pruefung_warnung_dressur_4: 'Die Teilnehmerzahl muss nur angegeben werden, wenn abweichend vom Dressurreglement.',
  pruefung_warnung_dressur_5: 'Lizenzen korrekt anwählen und nicht ausschliesslich vom Vorjahr kopieren. Die Bezeichnung der Brevetstufe ist BRK (Brevet Reiten Kombiniert) und BRD (Brevet Reiten Dressur) und nicht wie früher RB (Reitbrevet).',
  pruefung_warnung_dressur_6: '"Libre à la Carte" Prüfungen sind Spezialprüfungen (Modus "S").',
  pruefung_warnung_dressur_7: 'Prüfungen mit der Beschränkung "ausschliesslich für Ponys", sind offizielle Prüfungen (Modus "O").',
  pruefung_warnung_dressur_8: 'Ebenfalls gelten Prüfungen mit Beschränkungen betreffend Pferderassen (Araber, Barockpferde, Warmblüter usw.) als offizielle Prüfungen (Modus "O").',
  pruefung_warnung_dressur_9: 'Hingegen laufen die Freizeit / Promotionsprüfungen der Freiberger und Haflinger als Spezialprüfungen (Modus "S").',
  pruefung_warnung_dressur_10: 'Spezialprüfungen (SP/CD) können ausschliesslich in den Bemerkungen beschrieben werden.',
  pruefung_warnung_dressur_11: 'Bitte in den Fussdaten der Ausschreibung folgenden Satz vermerken:',
  pruefung_warnung_dressur_12: 'Dressurprogramme müssen nach dem aktuellen Stand geritten werden (https://swiss-equestrian.ch/de/Disziplinen/Dressur/Programme.html) und Kopfnummern sind in Dressurprüfungen obligatorisch.',

  pruefung_warnung_springen_1: 'Wir möchten Sie darauf hinweisen folgende Punkte zu beachten und gründlich zu prüfen:',
  pruefung_warnung_springen_2: 'Die Wertung A mit Zeitmessung ist in den Prüfungen bis B85 nicht erlaubt.',
  pruefung_warnung_springen_3: 'Wenn eine Prüfung mit Stufen ausgeschrieben wird, muss das Feld "Stufen" angewählt werden und die Stufen müssen in den Bemerkungen beschrieben werden.',
  pruefung_warnung_springen_4: 'Wenn eine Equipen Prüfung ausgeschrieben wird, muss das Feld "Equipe" angewählt und die Equipe definiert werden (2er, 3er, 4er), wenn zusätzlich Stufen ausgeschrieben werden, muss das Feld «Stufen» auch angewählt werden und die Stufen müssen in den Bemerkungen beschrieben werden.',
  pruefung_warnung_springen_5: 'Spezialprüfungen (SP/CS) können ausschliesslich in den Bemerkungen beschrieben werden.',

  pruefung_warnung_ca_1: 'Wir möchten Sie darauf hinweisen folgende Punkte zu beachten und gründlich zu prüfen:',
  pruefung_warnung_ca_2: 'Als offizielle Prüfungen gelten: Dressur- und Kegelfahren, reduzierte Vollprüfungen und Vollprüfungen.',
  pruefung_warnung_ca_3: 'Alle anderen Prüfungen gelten als Spezialprüfungen.',
  pruefung_warnung_ca_4: 'Spezialprüfungen (SP/CA) können ausschliesslich in den Bemerkungen beschrieben werden.',

  bilder_zu_klein_1: 'hochgeladenes Bild ist nicht hoch genug aufgelöst, um eine gute Druckqualität zu garantieren.',
  bilder_zu_klein_n: 'hochgeladene Bilder sind nicht hoch genug aufgelöst, um eine gute Druckqualität zu garantieren.',

  veranstaltungs_dokumente: 'Veranstaltungsdokumente',
  software_dokumente: 'Software',
  keine_dokumente: 'Keine Dokumente vorhanden',
  erstellt_am: 'Erstellt am',

  veranstalter_anzeigen: 'sichtbar',

  aktionen: 'Aktionen',

  melden: 'Melden',
  freigeben: 'Freigeben',
  loeschen: 'Löschen',
  absagen: 'Absagen',
  begruendung: 'Begründung',
  datensatz_loeschen_text: 'Wollen Sie den Datensatz wirklich löschen?',

  genehmigen_text: 'Wollen Sie die Veranstaltung genehmigen und zur Genehmigung an den SVPS schicken?',
  alle_genehmigen_text: 'Wollen Sie alle gemeldeten Veranstaltungen genehmigen und zur Genehmigung an den SVPS schicken?',
  alle_anlaesse_genehmigen_text: 'Wollen Sie alle provisorische Anlässe auf definitiv setzen?',
  melden_text: 'Wollen Sie die Veranstaltung dem Regionalverband %{regionalverband} melden?',
  loeschen_text: 'Wollen Sie die Veranstaltung wirklich löschen?',
  absagen_text: 'Begründung für die Absage:',
  alle_genehmigen: 'Alle genehmigen',
  genehmigen: 'Genehmigen',
  export_xlsx: 'Export XLSX',

  zurueckweisen: 'Zurückweisen',
  nennung_zurueckweisen: 'Nennung zurückweisen',
  zurueckweisen_falschnennung: 'Falschnennung: Kostenpflichtig – Nenngeld (inkl. Gebühren & Abgaben) muss nicht zurückbezahlt werden',
  zurueckweisen_zurueckgewiesen: 'Zurückweisung: Nicht kostenpflichtig – Überzählige Nennungen - Nenngeld (inkl. Gebühren & Abgaben) muss zurückerstattet werden',
  zurueckweisen_grund: 'Grund',
  zurueckweisungsgruende: 'Zurückweisungsgründe',
  gemeldete_veranstaltung_zurueckweisen: 'Bitte geben Sie eine Begründung für die Zurückweisung ein.',

  freigeben_titel: 'Ausschreibung zur Kontrolle freigeben',
  freigeben_frage: 'Nach der Übermittlungen können keine Korrekturen mehr vom Veranstalter vorgenommen werden! Sobald die Kontrolle durch den SVPS abgeschlossen ist, werden Sie per E-Mail informiert.',
  freigeben_funktionen: 'Mögliche Funktionen von Offiziellen pro Disziplin',
  freigeben_parcours: 'Die aufgeführten Parcoursbauer und/oder Parcoursbauer Cross haben für die Veranstaltung zugesagt.',
  freigeben_tierarzt: 'Der aufgeführte Tierarzt hat für die Veranstaltung zugesagt.',
  freigeben_jury_technik: 'Der aufgeführte Jurypräsident und/oder der Technischer Delegierter wurden für die Vorbereitung der Ausschreibung beigezogen und haben für die Veranstaltung zugesagt.',
  freigeben_notiz: 'Notiz für SVPS',
  freigeben_felder_fehlen: 'Sie haben noch nicht alle für eine Freigabe notwendigen Daten eingegeben. Bitte bestätigen Sie alle Rückfragen.',
  freigeben_daten_fehlen: 'Sie haben noch nicht alle für eine Freigabe notwendigen Daten eingegeben. In folgenden Formularen fehlen noch Daten:',
  freigeben_software_fehlt: 'Veranstaltungs-Software',
  freigeben_konto_fehlt: 'Kontodaten',
  freigeben_pruefungen_fehlen: 'Prüfungen',
  freigeben_pruefungen_ohne_max_nennungen: 'Max. Nennungen bei Prüfungen',

  changelog: 'Changelog',
  changelog_created: 'Nennung erstellt',
  changelog_updated: 'Letzte Modifikation',
  changelog_reiterwechsel: 'Reiterwechsel',
  changelog_reiterwechsel_2: '%{reiter_alt} ersetzt mit %{reiter_neu}',
  changelog_pferdewechsel: 'Pferdewechsel',
  changelog_pferdewechsel_2: '%{pferd_alt} ersetzt mit %{pferd_neu}',
  changelog_zurueckgewiesen_mit_grund: 'Nennung zurückgewiesen',
  changelog_zurueckgewiesen_mit_grund_2: 'Grund: %{grund}',
  changelog_zurueckgewiesen: 'Nennung zurückgewiesen',
  changelog_zurueckgewiesen_2: ' ',
  changelog_falschnennung_mit_grund: 'Falschnennung',
  changelog_falschnennung_mit_grund_2: 'Grund: %{grund}',
  changelog_falschnennung: 'Falschnennung',
  changelog_falschnennung_2: ' ',
  changelog_abgemeldet: 'Nennung abgemeldet',
  changelog_abgemeldet_2: ' ',

  pferde_angeben: 'Es müssen alle Pferde angeben werden, deren Impfung nicht in Ordnung war.',
  pferde_doppelt: 'Es wurde zweimal das selbe Pferd angegeben, was nicht erlaubt ist.',

  filtern: 'Filtern',
  zuruecksetzen: 'Zurücksetzen',
  nur_eigene: 'Nur eigene',
  sportjahr: 'Sportjahr',
  suche: 'Suche',

  pferde: 'Pferde',
  personen: 'Personen',

  programm_ids: 'Programme',
  programme: 'Programme',

  gwp_beschraenkungen_formatiert: "GWP",

  abmelden: 'Abmelden',
};
