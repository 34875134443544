import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-template"
export default class extends Controller {
  static targets = ["template", "templateOutput"]
  static values = {
    elementCountAsIdx: { type: Boolean, default: false },
    currentElementCount: { type: Number, default: 0 },
    maxElements: { type: Number, default: Infinity },
  }

  connect() {
    // console.group();
    // console.debug("form-template connected");
    // console.debug("idx", this.idxValue);
    // console.debug("currentElementCount", this.currentElementCountValue);
    // console.debug("maxElements", this.maxElementsValue);
    // console.debug("elementCountAsIdx", this.elementCountAsIdxValue);
    // console.groupEnd();
  }

  addTemplate() {
    if (this.currentElementCountValue >= this.maxElementsValue) return;

    this.currentElementCountValue++;

    const template = this.templateTarget.cloneNode(true).innerHTML.replace(/idx/g, this.idx);
    this.templateOutputTarget.insertAdjacentHTML("beforeend", template);
  }

  get idx() {
    if (this.elementCountAsIdxValue) {
      return this.currentElementCountValue;
    }

    return Date.now();
  }
}
